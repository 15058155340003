import { SearchHitFragment } from '../../generated/graphql';
import { useEffect } from 'react';
import getSqzlyCategoryName from '../../utils/getSqzlyCategoryName';
import useQueryParams from '../useQueryParams';
import bookingParamsConfig from '../../constants/bookingParams';
import { useIntl } from 'react-intl';
import isEqual from 'lodash.isequal';
import usePrevious from '../usePrevious';
import { unitTypeMessages } from '../../constants/getUnitTypeConsts';
import { LiteralElement } from 'intl-messageformat-parser';
import getLanguage from '../../utils/getLanguage';

const useAnalyticsUnitViewList = (hits?: SearchHitFragment[]) => {
    const [booking] = useQueryParams(bookingParamsConfig);
    const intl = useIntl();
    const ids = hits?.map(hit => hit.rentalUnit.id);
    const prevIds = usePrevious<string[]>(ids ?? []);

    useEffect(() => {
        if (!hits || isEqual(prevIds, ids) || hits.length === 0) {
            return;
        }

        window.dataLayer = window.dataLayer ?? [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: 'view_item_list',
            ecommerce: {
                items: hits.map(hit => ({
                    item_category: (unitTypeMessages[hit.rentalUnit.type].defaultMessage as LiteralElement[])[0].value,
                    item_id: hit.rentalUnit.code,
                    item_name: hit.rentalUnit.name,
                    item_variant:
                        booking.arrivalDate && booking.departureDate
                            ? `${booking.departureDate} - ${booking.arrivalDate}`
                            : null,
                    price: hit.trips?.[0]?.price,
                    currency: 'EUR',
                    quantity: 1,
                })),
            },
            squeezelyProducts: hits.map(hit => ({
                id: hit.rentalUnit.code,
                name: hit.rentalUnit.name,
                category_id: hit.rentalUnit ? getSqzlyCategoryName('T', intl.locale, hit.rentalUnit.type) : '',
                checkin_date: booking?.arrivalDate,
                checkout_date: booking?.departureDate,
                language: getLanguage(intl.locale),
                price: hit.trips?.[0]?.price,
                quantity: 1,
            })),
        });
    }, [booking, hits, ids, intl.locale, prevIds]);
};
export default useAnalyticsUnitViewList;
