import { UnitDetailsQuery } from '../../generated/graphql';
import { useEffect, useRef } from 'react';
import { Booking } from '../../entities/Booking';
import { useIntl } from 'react-intl';
import getSqzlyCategoryName from '../../utils/getSqzlyCategoryName';
import { unitTypeMessages } from '../../constants/getUnitTypeConsts';
import { LiteralElement } from 'intl-messageformat-parser';
import getLanguage from '../../utils/getLanguage';

const useAnalyticsUnitView = (rentalUnit?: UnitDetailsQuery['rentalUnit'], booking?: Booking) => {
    const { locale } = useIntl();
    const prev = useRef<UnitDetailsQuery['rentalUnit'] | null>(null);

    useEffect(() => {
        window.dataLayer = window.dataLayer ?? [];
        const shouldIgnore =
            // dates were selected, but no trip yet, so wait for that first.
            booking?.arrivalDate && booking.departureDate && !booking.trip;

        if (rentalUnit && !shouldIgnore && rentalUnit.id !== prev.current?.id) {
            prev.current = rentalUnit;
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: 'view_item',
                ecommerce: {
                    items: [
                        {
                            item_category: (unitTypeMessages[rentalUnit.type].defaultMessage as LiteralElement[])[0]
                                .value,
                            item_id: rentalUnit.code,
                            item_name: rentalUnit.name,
                            item_variant: booking?.trip
                                ? `${booking.departureDate} - ${booking.arrivalDate}`
                                : undefined,
                            price: booking?.trip?.price,
                            currency: 'EUR',
                            quantity: 1,
                        },
                    ],
                    value: booking?.trip?.price,
                },
                squeezelyProducts: [
                    {
                        id: rentalUnit.code,
                        name: rentalUnit.name,
                        category_id: rentalUnit ? getSqzlyCategoryName('T', locale, rentalUnit.type) : '',
                        checkin_date: booking?.arrivalDate,
                        checkout_date: booking?.departureDate,
                        language: getLanguage(locale),
                        price: booking?.trip?.price,
                        quantity: 1,
                    },
                ],
            });
        }
    }, [rentalUnit, booking, locale, prev]);
};

export default useAnalyticsUnitView;
